import React from 'react';
import { ComputerDesktopIcon, BoltIcon, WrenchIcon, CloudArrowDownIcon, PaintBrushIcon } from '@heroicons/react/24/outline';

const Software = () => {
  return (
    <div data-aos="fade-down" data-aos-duration="2000" data-aos-easing="ease-in-out" className=''>

    <div className="container mx-auto p-6">
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Our Software Tools</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        
        {/* Naval Architecture */}
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <ComputerDesktopIcon className="h-8 w-8 text-blue-600 mr-3" />
            <h2 className="text-2xl font-semibold text-blue-600">Naval Architecture</h2>
          </div>
          <ul className="list-disc pl-5 text-gray-700">
            <li>Aveva Marine</li>
            <li>AutoShip</li>
            <li>GHS – General Hydrostatics</li>
            <li>Maxsurf by Bentley product</li>
            <li>Solidworks (Marine)</li>
          </ul>
        </div>
        
        {/* Structural Analysis */}
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <WrenchIcon className="h-8 w-8 text-green-600 mr-3" />
            <h2 className="text-2xl font-semibold text-green-600">Structural Analysis</h2>
          </div>
          <ul className="list-disc pl-5 text-gray-700">
            <li>SACS System by Bentley (Offshore Structures)</li>
            <li>DNV Sesam FEM Analysis by DNV</li>
            <li>SAP2000 general purpose program for Structural Analysis</li>
          </ul>
        </div>
        
        {/* Mooring Analysis */}
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <BoltIcon className="h-8 w-8 text-red-600 mr-3" />
            <h2 className="text-2xl font-semibold text-red-600">Mooring Analysis</h2>
          </div>
          <ul className="list-disc pl-5 text-gray-700">
            <li>MOSES by Bentley for naval analysis and dynamic simulation</li>
            <li>Orcaflex</li>
          </ul>
        </div>
        
        {/* Flow & Fluid Analysis */}
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <CloudArrowDownIcon className="h-8 w-8 text-purple-600 mr-3" />
            <h2 className="text-2xl font-semibold text-purple-600">Flow & Fluid Analysis</h2>
          </div>
          <ul className="list-disc pl-5 text-gray-700">
            <li>Ansys Basic & Fluent (CFD)</li>
          </ul>
        </div>
        
        {/* Boat Design */}
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <ComputerDesktopIcon className="h-8 w-8 text-teal-600 mr-3" />
            <h2 className="text-2xl font-semibold text-teal-600">Boat Design</h2>
          </div>
          <ul className="list-disc pl-5 text-gray-700">
            <li>Rhino 3D Modelling</li>
            <li>Orca 3D</li>
            <li>Autohydro</li>
          </ul>
        </div>
        
        {/* Rendering */}
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <PaintBrushIcon className="h-8 w-8 text-orange-600 mr-3" />
            <h2 className="text-2xl font-semibold text-orange-600">Rendering</h2>
          </div>
          <ul className="list-disc pl-5 text-gray-700">
            <li>Blender</li>
            <li>Key-Shot</li>
            <li>V-ray</li>
            <li>Lumion</li>
          </ul>
        </div>
        
        {/* Drafting Tools */}
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <WrenchIcon className="h-8 w-8 text-gray-600 mr-3" />
            <h2 className="text-2xl font-semibold text-gray-600">Drafting Tools</h2>
          </div>
          <ul className="list-disc pl-5 text-gray-700">
            <li>AUTOCAD</li>
          </ul>
        </div>
        
      </div>
    </div>
    </div>
  );
};

export default Software;
