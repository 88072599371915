import React from 'react'
import client1 from "../../Assets/Logo_of_the_Royal_Institution_of_Naval_Architects.jpg"
import client2 from "../../Assets/client-02.jpg"
import client3 from "../../Assets/Logo_of_the_Royal_Institution_of_Naval_Architects.jpg"
import client4 from "../../Assets/client-02.jpg"
const Clients = () => {
    const logos = [client1, client2,client3,client4];
  return (
    <><div data-aos="fade-down" data-aos-duration="2000" data-aos-easing="ease-in-out" className=''>
    <div>
        <div className="flex justify-center items-center">
            <div className="bg-[#383772] h-10 w-2 rounded-2xl mt-[64px]"></div>

            <div className="bg-[#383772] h-16 w-2 rounded-2xl mt-[64px] ml-[8px]"></div>
            <div className="bg-[#383772] h-20 w-2 rounded-2xl mt-[72px] ml-[8px]"></div>
            <div className="bg-[#383772] h-24 w-2 rounded-2xl mt-[64px] ml-[8px]"></div>
            <div>
                <h1 className="text-[#383772] bg-white h-fit w-fit px-5 py-6 rounded-xl font-extrabold text-[40px] uppercase mt-14 ml-[8px]">
                    CLIENTS
                </h1>
            </div>
            <div className="bg-[#383772] h-24 w-2 rounded-2xl mt-[64px] ml-[8px]"></div>
            <div className="bg-[#383772] h-20 w-2 rounded-2xl mt-[72px] ml-[8px]"></div>
            <div className="bg-[#383772] h-16 w-2 rounded-2xl mt-[80px] ml-[8px]"></div>
            <div className="bg-[#383772] h-10 w-2 rounded-2xl mt-[72px] ml-[8px]"></div>

        </div>
    </div>

</div>
<div data-aos="fade-right" data-aos-duration="2000" data-aos-easing="ease-in-out" className='section-title'>
      
<div className="container mx-auto py-8">
      <div className="flex flex-wrap justify-center items-center gap-6">
        {logos.map((logo, index) => (
          <div key={index} className="w-24 h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 flex items-center justify-center bg-white shadow-lg rounded-lg">
            <img src={logo} alt={`Client ${index + 1}`} className="max-w-full max-h-full object-contain" />
          </div>
        ))}
      </div>
    </div>
    </div>
    </>
  )
}

export default Clients