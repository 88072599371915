import React from 'react';
import { GlobeAltIcon, WrenchIcon, SunIcon, UserGroupIcon, AcademicCapIcon } from '@heroicons/react/24/outline';

const Expertise = () => {
  return (
    <div data-aos="fade-down" data-aos-duration="2000" data-aos-easing="ease-in-out" className=''>

    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-12 text-center text-gray-800">Our Expertise</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <GlobeAltIcon className="h-8 w-8 text-indigo-600 mr-3" />
            <h2 className="text-2xl font-semibold text-indigo-600">Boat Design & Building</h2>
          </div>
          <p className="text-gray-700">
            Expertise in designing and building boats with a focus on performance, safety, and aesthetics.
          </p>
        </div>
        
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <GlobeAltIcon className="h-8 w-8 text-indigo-600 mr-3" />
            <h2 className="text-2xl font-semibold text-indigo-600">Marinas & Marine Terminals</h2>
          </div>
          <p className="text-gray-700">
            Design and construction of marinas, floating piers, marine terminals, and jetties.
          </p>
        </div>
        
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <WrenchIcon className="h-8 w-8 text-indigo-600 mr-3" />
            <h2 className="text-2xl font-semibold text-indigo-600">Structural Platforms</h2>
          </div>
          <p className="text-gray-700">
            Including offshore platforms for oil & gas with a focus on durability and safety.
          </p>
        </div>
        
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <AcademicCapIcon className="h-8 w-8 text-indigo-600 mr-3" />
            <h2 className="text-2xl font-semibold text-indigo-600">Project Management</h2>
          </div>
          <p className="text-gray-700">
            Comprehensive management of projects from inception through to completion.
          </p>
        </div>
        
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <UserGroupIcon className="h-8 w-8 text-indigo-600 mr-3" />
            <h2 className="text-2xl font-semibold text-indigo-600">Ship Design & Marine Structures</h2>
          </div>
          <p className="text-gray-700">
            Expertise in designing ships and marine structures, ensuring optimal performance and compliance.
          </p>
        </div>
        
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <SunIcon className="h-8 w-8 text-indigo-600 mr-3" />
            <h2 className="text-2xl font-semibold text-indigo-600">Offshore Wind & Renewable Energy</h2>
          </div>
          <p className="text-gray-700">
            Designing floating renewable energy structures and offshore wind farms to support sustainable energy solutions.
          </p>
        </div>
        
      </div>
    </div>
    </div>
  );
};

export default Expertise;
