import React from 'react'
import project1 from "../../Assets/project01.jpg"
import project2 from "../../Assets/project2.jpg"
import { Link } from 'react-router-dom'


const Projecthome = () => {
  return (
    <>
      <div data-aos="fade-down" data-aos-duration="2000" data-aos-easing="ease-in-out">
        <div>
          <div className="flex justify-center items-center">
            <div className="bg-[#383772] h-10 w-2 rounded-2xl mt-[64px]"></div>
            <div className="bg-[#383772] h-16 w-2 rounded-2xl mt-[64px] ml-[8px]"></div>
            <div className="bg-[#383772] h-20 w-2 rounded-2xl mt-[72px] ml-[8px]"></div>
            <div className="bg-[#383772] h-24 w-2 rounded-2xl mt-[64px] ml-[8px]"></div>
            <div>
              <h1 className="text-[#383772] bg-white h-fit w-fit px-5 py-6 rounded-xl font-extrabold text-[40px] uppercase mt-14 ml-[8px]">
                PROJECTS
              </h1>
            </div>
            <div className="bg-[#383772] h-24 w-2 rounded-2xl mt-[64px] ml-[8px]"></div>
            <div className="bg-[#383772] h-20 w-2 rounded-2xl mt-[72px] ml-[8px]"></div>
            <div className="bg-[#383772] h-16 w-2 rounded-2xl mt-[80px] ml-[8px]"></div>
            <div className="bg-[#383772] h-10 w-2 rounded-2xl mt-[72px] ml-[8px]"></div>
          </div>
        </div>
      </div>
      <div data-aos="fade-right" data-aos-duration="2000" data-aos-easing="ease-in-out" className='section-title'>
        <div className='flex flex-col md:flex-row justify-center space-y-8 md:space-y-0 md:space-x-8 mt-4 mb-8'>
          <div className='relative group'>
            <div className='bg-white shadow-lg rounded-lg h-80 w-full md:w-72'>
              <img src={project1} alt="project1" className='rounded-md h-[180px] w-full transition-opacity duration-300 group-hover:opacity-50' />
              <div className='absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-[#383772] hover:cursor-pointer bg-opacity-80 rounded-lg text-white p-3'>
                <p className='text-xl text-center'>A complete design of ASSV for our prestigious Indian Navy client. The vessel has a dual autonomous system with a maximum speed of 35 knots. The FEED design approval is in progress.</p>
              </div>
              <div className='p-3'>
                <h1 className='text-red-600 text-xl'>May, 2021</h1>
                <h2 className='text-[#383772] text-xl'>
                  16.0M Advanced Sea-Surface Vessel (ASSV)
                </h2>
              </div>
            </div>
          </div>
          <div className='relative group'>
            <div className='bg-white shadow-lg rounded-lg h-80 w-full md:w-72'>
              <img src={project2} alt="project2" className='rounded-md h-[180px] w-full transition-opacity duration-300 group-hover:opacity-50' />
              <div className='absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-[#383772] hover:cursor-pointer bg-opacity-80 rounded-lg text-white p-3'>
                <p className='text-xl text-center'>Research has been performed on the catamaran and generated the intact & damaged stability booklet.</p>
              </div>
              <div className='p-3'>
                <h1 className='text-red-600 text-xl'>February, 2021</h1>
                <h2 className='text-[#383772] text-xl'>
                  5 Case Study: 18.0M Autonomous Self-Driven Catamaran
                </h2>
              </div>
            </div>
          </div>
          <div className='relative group'>
            <div className='bg-white shadow-lg rounded-lg h-80 w-full md:w-72'>
              <img src={project2} alt="project2" className='rounded-md h-[180px] w-full transition-opacity duration-300 group-hover:opacity-50' />
              <div className='absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-[#383772] hover:cursor-pointer bg-opacity-80 rounded-lg text-white p-3'>
                <p className='text-xl text-center'>An unmanned surveillance boat was designed to give a solution for the coast guards to improve coastal security, help to minimize manpower cost through remote operation & better performance at any environmental conditions.</p>
              </div>
              <div className='p-3'>
                <h1 className='text-red-600 text-xl'>October, 2020</h1>
                <h2 className='text-[#383772] text-xl'>
                  Unmanned Surveillance Boat
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-center mb-5'>
          <Link to="project">
            <button className="bg-[#383772] hover:bg-[#201f5c] text-white kanit-medium py-2 px-4 rounded">
              View All
            </button>
          </Link>
        </div>
      </div>
    </>
  )
}

export default Projecthome
