import React from 'react';
import logo from '../Assets/companylogo.jpeg';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-12">
      <div className="container  mx-auto flex flex-wrap justify-between items-center space-y-6 md:space-y-0">
        {/* Logo Section */}
        <div className="w-full md:w-auto md:ml-24 flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-6">
          <img src={logo} alt="Logo" className="h-24 mx-auto md:mx-0" />
          <div className="text-center md:text-left">
            <h3 className="text-lg font-semibold">DREAM OCEAN</h3>
            <p>101, Long Bazaar Street</p>
            <p>Vellore - 632 004</p>
            <p>Tamil Nadu, India.</p>
          </div>
        </div>

        {/* Contact Section */}
        <div className="w-full md:w-1/3 md:ml-12 text-center md:text-left">
          <h3 className="text-lg font-semibold">Contact Us</h3>
          <p>Email: najeef.aj@dreamoceangroup.com</p>
          <p>CIN: U45309 TN2020 PTC154819</p>
        </div>

     
        <div className="w-full md:w-1/3  text-center md:text-left">
          <h3 className="text-lg font-semibold">Follow Us</h3>
          <div className="flex justify-center md:justify-start space-x-4 mt-2">
            <a href="https://www.facebook.com/people/Dream-Ocean-Engineering-Consultants/100069748610592/" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
            <a href="https://www.linkedin.com/company/dream-ocean/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
            <a href="https://www.instagram.com/dream_ocean_engg_consultancy/" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
