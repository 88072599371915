import React from 'react';
import { AcademicCapIcon, HomeIcon, WrenchIcon, CubeIcon, SunIcon, DocumentTextIcon, BriefcaseIcon } from '@heroicons/react/24/outline';

const Services = () => {
  return (
    <div data-aos="fade-down" data-aos-duration="2000" data-aos-easing="ease-in-out" className=''>
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold mb-12 text-center text-gray-800">Our Services</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Existing Sections */}
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <AcademicCapIcon className="h-8 w-8 text-indigo-600 mr-3" />
            <h2 className="text-2xl font-semibold text-indigo-600">NAVAL ARCHITECTURE & MARINE DESIGN</h2>
          </div>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>Conceptual / Basic / Detail Design</li>
            <li>Marine Structural analysis</li>
            <li>Stability, Resistance, Powering, Motion analysis</li>
            <li>Vessel Performance analysis</li>
            <li>Construction drawings</li>
            <li>Classification societies approvals</li>
            <li>Owner’s document deliverables</li>
            <li>Mooring Analysis</li>
          </ul>
        </div>

        

        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <CubeIcon className="h-8 w-8 text-indigo-600 mr-3" />
            <h2 className="text-2xl font-semibold text-indigo-600">OIL & GAS</h2>
          </div>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>Platform/Jacket/Piling Design for the In Service and Installation Conditions</li>
            <li>Loadout, Transportation, Installation & Lifting engineering</li>
            <li>Method Engineering</li>
            <li>FEM Analysis of Special Structures and Critical Joints</li>
            <li>FEED design of Offshore Platforms</li>
          </ul>
        </div>

        {/* New Sections */}
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <BriefcaseIcon className="h-8 w-8 text-indigo-600 mr-3" />
            <h2 className="text-2xl font-semibold text-indigo-600">SPECIALIST SERVICES</h2>
          </div>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>Effective Bid Design and Cost Estimate for Platforms, Marine Structures, Boat & Marinas</li>
            <li>Legal assistance to Client During Certification Process & Class approvals</li>
            <li>Safety Studies</li>
            <li>Shipyard facility design</li>
          </ul>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <HomeIcon className="h-8 w-8 text-indigo-600 mr-3" />
            <h2 className="text-2xl font-semibold text-indigo-600">BOAT & MARINAS DESIGN</h2>
          </div>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>Design of Boats / Crafts / Yachts / Luxury Marinas / Floating House-boats</li>
            <li>High class model with Realistic render</li>
            <li>Design of Gangways, bollards and other accessories</li>
          </ul>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <WrenchIcon className="h-8 w-8 text-indigo-600 mr-3" />
            <h2 className="text-2xl font-semibold text-indigo-600">YARD WORKS</h2>
          </div>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>Fabrication of steel secondary structure</li>
            <li>Boat Building (Steel & Aluminum)</li>
          </ul>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <SunIcon className="h-8 w-8 text-indigo-600 mr-3" />
            <h2 className="text-2xl font-semibold text-indigo-600">RENEWABLE ENERGY STRUCTURE</h2>
          </div>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>Conceptual design of floating solar panels</li>
            <li>R & D on various types of renewable energy from Ocean technology</li>
          </ul>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6 transform transition-transform hover:scale-105">
          <div className="flex items-center mb-4">
            <DocumentTextIcon className="h-8 w-8 text-indigo-600 mr-3" />
            <h2 className="text-2xl font-semibold text-indigo-600">TENDERING</h2>
          </div>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>Design Optimization</li>
            <li>Procedures / Preliminary calculation</li>
            <li>Construction & Installation feasibility studies</li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Services;
