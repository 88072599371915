import React from 'react'
 
import Carousel from './Carousel'
import Aboutus from './Aboutus'
import Projecthome from './Projects/Projecthome'
import Clients from './Clients/Clients'
 
 
const Main = () => {
  return (
    <div> 
    <Carousel/>
    <Aboutus/>
    <Projecthome/>
    {/* <OtherActivity/> */}
   <Clients/>
   
   </div>
  )
}

export default Main