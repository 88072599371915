import { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes,  } from "react-router-dom";
 
import AOS from 'aos';
 
import 'aos/dist/aos.css';
 
import Main from './Components/Ui/Main';
// import Navbar from './Components/Ui/NavFot';
import NavFot from './Components/Ui/NavFot';
import Servies from './Components/Ui/Servies/Servies';
import Expertise from './Components/Ui/Expertise/Expertise';
import Software from './Components/Ui/Software/Software';
import Project from './Components/Ui/Projects/Project';
import Contact from './Components/Ui/Contact';
function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  return (
    <div className="App">
  {/* <Main/> */}
 
        <BrowserRouter>
        <Routes>

        <Route path="/" element={<NavFot />}>
        <Route index element={<Main />} />
        <Route path="servies" element={<Servies />} />
        <Route path="EXPERTISE" element={<Expertise />} />
        <Route path="project" element={<Project />} />

        <Route path="software" element={<Software />} />
        <Route path="contact" element={<Contact />} />





</Route>
</Routes>
        </BrowserRouter>
   
    </div>
  );
}

export default App;
