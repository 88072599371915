import React from 'react';
import project1 from "../../Assets/project01.jpg";
import project2 from "../../Assets/project2.jpg";

const Project = () => {
  return (
    <div data-aos="fade-down" data-aos-duration="2000" data-aos-easing="ease-in-out" className='section-title'>
      <h1 className="text-4xl font-bold mb-12 text-center text-gray-800">Our Projects</h1>

      <div className='flex flex-wrap justify-center space-x-0 md:space-x-8 mt-4 mb-8'>
        <div className='relative group m-4 md:m-0'>
          <div className='bg-white shadow-lg rounded-lg h-80 w-72'>
            <img src={project1} alt="project1" className='rounded-md h-[180px] transition-opacity duration-300 group-hover:opacity-50' />
            <div className='absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-[#383772] hover:cursor-pointer bg-opacity-80 rounded-lg text-white p-3'>
              <p className='text-xl text-center'>A complete design of ASSV for our prestigious Indian Navy client. The vessel has a dual autonomous system with a maximum speed of 35 knots. The FEED design approval is in progress.</p>
            </div>
            <div className='p-3'>
              <h1 className='text-red-600 text-xl'>May, 2021</h1>
              <h2 className='text-[#383772] text-xl'>
                16.0M Advanced Sea-Surface Vessel (ASSV)
              </h2>
            </div>
          </div>
        </div>

        <div className='relative group m-4 md:m-0'>
          <div className='bg-white shadow-lg rounded-lg h-80 w-72'>
            <img src={project2} alt="project2" className='rounded-md h-[180px] transition-opacity duration-300 group-hover:opacity-50' />
            <div className='absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-[#383772] hover:cursor-pointer bg-opacity-80 rounded-lg text-white p-3'>
              <p className='text-xl text-center'>Research has been performed on the catamaran and generated the intact & damaged stability booklet.</p>
            </div>
            <div className='p-3'>
              <h1 className='text-red-600 text-xl'>February, 2021</h1>
              <h2 className='text-[#383772] text-xl'>
                5 Case Study: 18.0M Autonomous Self-Driven Catamaran
              </h2>
            </div>
          </div>
        </div>

        <div className='relative group m-4 md:m-0'>
          <div className='bg-white shadow-lg rounded-lg h-80 w-72'>
            <img src={project2} alt="project2" className='rounded-md h-[180px] transition-opacity duration-300 group-hover:opacity-50' />
            <div className='absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-[#383772] hover:cursor-pointer bg-opacity-80 rounded-lg text-white p-3'>
              <p className='text-xl text-center'>An unmanned surveillance boat was designed to give a solution for the coast guards to improve coastal security, help to minimize manpower cost through remote operation & better performance at any environmental conditions.</p>
            </div>
            <div className='p-3'>
              <h1 className='text-red-600 text-xl'>October, 2020</h1>
              <h2 className='text-[#383772] text-xl'>
                Unmanned Surveillance Boat
              </h2>
            </div>
          </div>
        </div>

        <div className='relative group m-4 md:m-0'>
          <div className='bg-white shadow-lg rounded-lg h-80 w-72'>
            <img src={project2} alt="project2" className='rounded-md h-[180px] transition-opacity duration-300 group-hover:opacity-50' />
            <div className='absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-[#383772] hover:cursor-pointer bg-opacity-80 rounded-lg text-white p-3'>
              <p className='text-xl text-center'>M/V Lanka Freedom was built in 1992, with a double hull, under the Japanese Flag. In 2017, it was transferred to Shanika Marine Company, Srilanka. We were involved to get classification approval for the Ship’s stability.</p>
            </div>
            <div className='p-3'>
              <h1 className='text-red-600 text-xl'>September, 2020</h1>
              <h2 className='text-[#383772] text-xl'>
                550MT Sludge Tanker
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
