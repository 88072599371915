import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import {  Outlet } from "react-router-dom";

const  NavFot = () => {
  return (
    <div className="w-full h-full">
    <Navbar />
    <div className="mt-28 ">
      < Outlet/>
    </div>
    <div className="">
      <Footer />
    </div>
  </div>
  )
}

export default  NavFot