import React, { useEffect, useState } from 'react';
import 'aos/dist/aos.css'; // Import AOS CSS for animations
import { FaAnchor, FaShip } from 'react-icons/fa'; // Import some icons

const Aboutus = () => {
  const [text, setText] = useState('');
  const fullText = 'We are devoted to making an optimistic influence on our clients where we operate by using our consistent skills and industrial competence. We supply effective & tailored design solutions starting from FEED to installation and commissioning.';
  const typingSpeed = 40; // Adjust typing speed (in ms) as needed

  useEffect(() => {
    let index = 0;
    const typingInterval = setInterval(() => {
      setText(fullText.slice(0, index));
      index++;
      if (index > fullText.length) {
        clearInterval(typingInterval);
      }
    }, typingSpeed);
    return () => clearInterval(typingInterval);
  }, []);

  return (
    <div 
      data-aos="fade-down" 
      data-aos-duration="3000" 
      data-aos-easing="ease-in-out" 
      className="section-title p-6 md:p-12 lg:p-16 text-black"
    >
      <div className="max-w-4xl mx-auto text-center bg-white shadow-md rounded-xl p-5">
        {/* <div className="flex justify-center items-center mb-6">
          <FaAnchor className="text-4xl mr-4" />
          <FaShip className="text-4xl" />
        </div> */}
        <p className="text-lg md:text-xl lg:text-2xl kanit-medium">
          DREAM OCEAN IS AN ENGINEERING DESIGN CONSULTANCY COMPANY SPECIALIZED IN BOAT DESIGN / MARINE / OFFSHORE FIELD.
        </p>
        <div className="mt-6 text-base md:text-lg lg:text-xl w-full">
          <p className="whitespace-pre-wrap overflow-hidden">
            {text}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
