import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from "../Assets/Logo.jpg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className='fixed top-0 left-0 w-full bg-white shadow-md rounded-md text-black kanit-medium text-xl p-4 z-50'>
      <div className='flex justify-between items-center'>
        <Link to="/">
          <div className='md:w-[260px] w-[190px]'>
            <img src={logo} alt="logo" className='w-full h-auto' />
            <h1 className='text-[#383772]  text-xs md:text-md'>Where Design Takes Life!</h1>
          </div>
        </Link>
        <div className='hidden md:block'>
          <ul className='flex space-x-11 p-4'>
            <Link to="/"><li>Home</li></Link>
            <Link to="/servies"><li>Services</li></Link>
            <Link to="/expertise"><li>Expertise</li></Link>
            <Link to="/software"><li>Software</li></Link>
            <Link to="/project"><li>Projects</li></Link>
            <Link to="/contact"><li>Contact Us</li></Link>
          </ul>
        </div>
        <div className='md:hidden'>
          <button onClick={toggleMenu} className='focus:outline-none'>
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </div>
      {isOpen && (
        <div className='md:hidden'>
          <ul className='flex flex-col space-y-4 mt-4'>
            <Link to="/" onClick={toggleMenu}><li>Home</li></Link>
            <Link to="/servies" onClick={toggleMenu}><li>Services</li></Link>
            <Link to="/expertise" onClick={toggleMenu}><li>Expertise</li></Link>
            <Link to="/software" onClick={toggleMenu}><li>Software</li></Link>
            <Link to="/project" onClick={toggleMenu}><li>Projects</li></Link>
            <Link to="/contact" onClick={toggleMenu}><li>Contact Us</li></Link>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
